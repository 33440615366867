import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FcCustomerSupport } from "react-icons/fc";
import { IoMdMail } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import ContactForm from "./ContactForm";

export default function ContactBody() {
  return (
    <div>
      <Container>
        <Row
          xs={1}
          md={2}
          lg={3}
          className="g-4"
          style={{ margin: "100px auto" }}
        >
          <Col>
            <Card>
              <Card.Body className="text-center py-5">
                <FcCustomerSupport size={38} className="mb-4" color="#000000" />
                <Card.Title>Call Us</Card.Title>
                <Card.Text>
                  <a
                    className="text-black text-decoration-none"
                    href="tel:+918272068460"
                  >
                    +91 8272068460
                  </a>
                  <br />
                  <a
                    className="text-black text-decoration-none"
                    href="tel:+918272068459"
                  >
                    +918272068459
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body className="text-center py-5">
                <IoMdMail size={38} className="mb-4" />
                <Card.Title>Email Us</Card.Title>
                <Card.Text>
                  <a className="text-black text-decoration-none" href="mailto:">
                    dsglobalhospital@gmail.com
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body className="text-center py-5">
                <IoLocationOutline size={38} className="mb-4" />
                <Card.Title>Location</Card.Title>
                <Card.Text>
                  Aasaf Nagar, Bijowli Chowk, Near Godawari Hotel, beside
                  Kajaria Showroom, Chak no 389, Gata no 245/2, Roorkee-Delhi
                  Road, Roorkee-247666, District Haridwar, Uttarakhand
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ContactForm />
      <Container fluid className="mt-5 p-0">
        
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3461.5041121482263!2d77.87851831744385!3d29.820867500000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eb3619078450b%3A0x654c236125d8e8e2!2sDeepak%20Savitri%20GLOBAL%20HEART%20HOSPITAL!5e0!3m2!1sen!2sin!4v1676173439196!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          title="hospitalLocation"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Container>
    </div>
  );
}
