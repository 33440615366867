import React from 'react'
import { Container } from 'react-bootstrap'
import ContactForm from "./ContactForm";

export default function DonationPage() {
    return (
      <div>




<div class="containerdonation">
      <img
        src="https://i.ibb.co/nCpNmL7/under.jpg"
      />

      <div class="centered">
        <h1 style={{ color: "Green" }}>Website under construction</h1>
        <h6>We will be live soon</h6>
      </div>
    </div>



      </div>
    )
  }
