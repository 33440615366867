import React from 'react'
import AboutSpecialist from './AboutSpecialist'
import { Container } from 'react-bootstrap'
import AboutTitle from './AboutTitle'
import Footer from './Footer'
import NavbarTop from './Navbar'
import Donationbanner from './donationbanner'
import Labbooking from './labbooking'
export default function LabbookingPage() {
  return (
    <div> 
       <Donationbanner/>
        <NavbarTop/>
        <div className="contactTitle">
      <Container fluid className="text-center">
        <p>Examination And Medicine Booking</p>
        <h2>at your finger tips</h2>
      </Container>
    </div>
       <Labbooking />
        <Footer/>
    </div>
  )
}
