import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/AboutPage";
import Accreditation from "./components/Accreditationpage";
import BlogPage from "./components/BlogPage";
import Breakthroughcases from "./components/Brakethroughcases";
import Cardiac from "./components/cardiac";
import ContactPage from "./components/ContactPage";
import Department from "./components/Department";
import DoctorsPage from "./components/DoctorsPage";
import DonationPage from "./components/DonationPage";
import Feedback from "./components/Feedback";
import Gallery from "./components/Gallery";
import Home from "./components/Home";
import LabbookingPage from "./components/LabbookingPage";
import MakeAppointment from "./components/makeappointmentpage";
import ServicePage from "./components/ServicePage";
import ServiceDetails from "./components/services/Echocardiography";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="services" element={<ServicePage />} />
        <Route path="doctors" element={<DoctorsPage />} />
        <Route path="department" element={<Department />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="blogs" element={<BlogPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="donation" element={<DonationPage />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="labbooking" element={<LabbookingPage />} />
        <Route path="accreditation" element={<Accreditation />} />
        <Route path="brakethroughcases" element={<Breakthroughcases />} />
        <Route path="makeappointment" element={<MakeAppointment />} />
        <Route path="cardiac" element={<Cardiac />} />
        <Route path="services/:service" element={<ServiceDetails />} />
      </Routes>
    </div>
  );
}

export default App;
