import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import NavbarTop from "./Navbar";
import sanityClient from "../sanity";

export default function Breakthroughcases() {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="gallery"] {
                    caption,
                    image{
                        asset -> {
                            _id,
                            url
                        },
                        alt
                    },
                }`
      )
      .then((data) => setGallery(data))
      // .then((data) => console.log(data))
      .catch(console.error);
  }, []);
  console.log(gallery);

  const renderImages = (gallery, index) => {
    return (
      <Col>
        <img src={gallery.image.asset.url} className="w-100" alt="" />
      </Col>
    );
  };

  return (
    <div>
      <NavbarTop />
      <Container style={{ minHeight: "50vh", margin: "100px auto" }}>
        <Row xs={1} md={2} lg={3} className="g-4">
          {gallery.map(renderImages)}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
