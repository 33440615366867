import React from "react";
import Departmentbody from "./Departmentbody";
import DoctorsBody from "./DoctorsBody";
import Footer from "./Footer";
import NavbarTop from "./Navbar";

export default function Department() {
  return (
    <div>
      <NavbarTop />
     <Departmentbody />
      <Footer />
    </div>
  );
}
