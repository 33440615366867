import React from "react";
import { Container } from "react-bootstrap";

export default function ServiceTitle() {
  return (
    <div className="serviceTitle-main">
      <Container fluid className="text-center">
        <p>Our Services</p>
        <h2>What We Do</h2>
      </Container>
    </div>
  );
}
