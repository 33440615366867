import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';

export default function AboutTitle() {
  return (
    <div className="aboutTitle-main">
      <Container fluid className="text-center">
        <p>About Us</p>
        <h2>About Us</h2>
      </Container>
      <Container>
        <Row>
          <Col sm={4}>
            <h2>Personal care for your healthy living</h2>
          </Col>
          <Col sm={8}>
            <p style={{ fontSize: "18px" }}>
              <h2>Welcome</h2> To Deepak Savitri Global Hospital and Heart Care, your number one source for Healthcare. We’re dedicated to providing you the very best of Medical Facilities in Roorkee, Haridwar, Uttarakhand. We are committed to provide the best treatment to the patients while focusing on raising the healthcare standards. We have a professional and adept medical team who work with the best of the world's medical technologies and international evidence-based protocols to provide the most comprehensive treatment across all specialties of medicines. Our hospital offers a wide spectrum of advanced medicine, a perfect blend of multidisciplinary services to patients of all ages and different backgrounds.
            </p>
            <Col>
              <h4>We Offers many services related to following departments:</h4>
            </Col>
            <h5>
              <ul>
                <li>Cardiac</li>
                <li>General Medicines</li>
                <li>Trauma</li>
              </ul>
            </h5>
            <div className="about_description">
              <p style={{ fontSize: "18px" }}>
                Founded in 2022, Deepak Savitri Global Hospital and Heart Care Hospital has started its journey and is being appreciated for his quality and honest services. Our passion for Healthcare drove us to start this Hospital.
                The hallmark of this Hospitals is a committed team of doctors and caregivers, who take up the challenge of treating the most complicated cases, besides stepping up to handle Emergency and Critical Care with dedication, expertise and compassion. Our trained specialists and health experts have the highest level of proficiency and are committed to execute seamless services at a fraction of the cost. Over the last two years Dr. Mahendra and his Team has made immense contributions to the lives of people in Haridwar District and nearby region
              </p>
            </div>
            {/* <h3 style={{ textAlign: 'center' }}><a href=''>Download HOSPITAL PROFILE & BROCHURE</a></h3> */}

            <h2 style={{  }}>A Brief note on Institution: - </h2>
            <p style={{ textAlign: "justify", fontSize: "18px" }}>
              In order to protect and care for the old, weak, sick and maimed cows of indigenous Indian breeds who are often thrown out on streets or sold to butchers when they stop producing milk, Sadhu-Saints of Haridwar-Rishikesh decided to set up Shree Krishnayan Desi Gauraksha Evam Golokdham Sewa Samiti.
              The Shree Krishnayan Desi Gaurakshashala Avam Golokdham Sewa Samiti is a charitable society established in 2011 in District- Haridwar, Uttarakhand. We started with just 11 cows saved from the hands of butchers in the year 2010 in Haridwar, and presently we shelter and feed more than 20000 Desi Indian non milching stray Cows, Bulls and her progenies in India. This number is ever-increasing as stray Desi Cows & Bulls mostly deserted by their owner are brought to our Gaurakshashala every day.
            </p>

            <h4>Other Main Objectives of the Institution is: -</h4>
            <h5>Promotion of Health Care.</h5>
            <hr />
            <h5>Promotion of Healthy Vedic Life Style.</h5>
            <hr />
            <h5>Promotion of Organic and Natural Farming. </h5>
            <hr />
            <ol type="1">
              <li><h5>Vision</h5></li>
              <p style={{ textAlign: "justify", fontSize: "18px" }}>Our vision is to establish a Center of Excellence in Health Care and to be recognized globally for excellence in patient-centred healthcare. It is also a key vision to provide multi-super-specialty expertise with affordable and accurate treatment to the patients so that patients get the right treatment at the subsidised affordable rates in every part of the country.</p>

              <li><h5>Mission</h5></li>
              <p style={{ textAlign: "justify", fontSize: "18px" }}>We strive for excellence in everything we do, so we can provide the highest standards of patient-cantered care. Our mission is Caring for life and serving humanity by providing best and honest healthcare services at subsidised cost and to spread good ethics, values, principles, among peoples not by just preaching but also by action. </p>

              <li><h5>Values</h5></li>
              <p style={{ textAlign: "justify", fontSize: "18px" }}>
                Compassion, Excellence, Efficiency and Consistency are our core values.  We want to make world class healthcare accessible to all. Maintain integrity and the highest standards by putting the best interests of the patient first. We demonstrate our moral courage to do the right thing.
              </p>

              <p style={{ textAlign: "justify", fontSize: "18px" }}>  Patient Centric<br />
•	Resolve to ‘Best results and experience’ for our patients.<br />
•	Treat patients and their guardians with sympathy, care and comprehension.<br />
<br />
Trustworthiness<br />
•	Be principled and transparent.<br />
•	Model and live our ‘Qualities’.<br />
•	Exhibit moral fortitude to shout out and do the correct things.<br />
</p>

              <li><h5>Commitment</h5></li>
              <p style={{ textAlign: "justify", fontSize: "18px" }}>
                Determined to provide quality health care and to serve mankind and protect Desi Gauvansh.
              </p>
              <a
              href="/doctors"
              className="btn btn-primary"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
            Management Team
            </a>
            </ol>
{/* 
            <h4>Patient Centric</h4> */}
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row xs={1} md={2} lg={4} className="g-4">
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1551884170-09fb70a3a2ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Healthcare for Kids
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1564732005956-20420ebdab60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTB8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Medical Counseling
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1551884170-09fb70a3a2ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Modern Equipments
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1564732005956-20420ebdab60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTB8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Qualified Doctors
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
      {/* <Container>
        <Row>
          <Col sm={4} className="d-flex flex-column justify-content-center">
            <h2>Our Doctors achievements</h2>
            <div className="line"></div>
          </Col>
          <Col sm={8}>
            <Row xs={1} md={2} lg={3} className="g-2 text-center mt-4 mt-sm-0">
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
}
