import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../assets/logo.jpg";
import { ImLocation } from "react-icons/im";
import { HiMail } from "react-icons/hi";
import { isDesktop, isTablet } from "react-device-detect";
import { FiPhoneCall } from "react-icons/fi";
import { Link, Router, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Googletranslate from "./Googletranslate";

export default function NavbarTop() {
  const navigation = useNavigate();
  return (
    <div>
      <Container
        fluid
        style={{
          backgroundColor: "#213b66",
          display: "flex",
          justifyContent: "space-between",
          color: "#fff",
          padding: "5px",
        }}
      >
        <div className="navEmail">
          <>
            <span> </span>
            <a
              class="white-link"
              href="/"
              style={{
                marginRight: "10px",
                marginLeft: "",
                height: 0,
                color: "#FFFFFF;",
              }}
            >
              Home
            </a>
          </>
          <HiMail size={24} />{" "}
          {isDesktop || isTablet ? <span>Email:- </span> : ""}
          <a href="mailto:dsglobalhospital@gmail.com" className="text-light">
            {isDesktop || isTablet ? "dsglobalhospital@gmail.com" : "Write Us"}
          </a>
          {isTablet || isDesktop ? (
            <>
              <span className="ms-5">
                {" "}
                <ImLocation size={20} />
              </span>
              <a
                class="white-link"
                href="/contact"
                style={{
                  marginRight: "10px",
                  marginLeft: "10px",
                  height: 0,
                  color: "#FFFFFF;",
                }}
              >
                Contact
              </a>
            </> 
          ) : (
            ""
          )}
        </div>
<Googletranslate/>
{/* <span>
      <div id="google_translate_element"></div>
      <script> 
      function googleTranslateElementInit() {
        new google.translate.TranslateElement({
          pageLanguage: 'en'
        }, 'google_translate_element')};
      
      </script>
      <script src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script> 
  </span> */}

        <div className="topCall">
          {isDesktop || isTablet ? <span>Call Now:- </span> : <FiPhoneCall />}{" "}
          <a href="tel:+918813985890" className="text-light">
            +91 8813985890
          </a>
          {/* <>
            <span> </span>
            <a
              class="white-link"
              href="/offcanvasNavbar-expand-xxl"
              style={{
                marginRight: "10px",
                marginLeft: "30px",
                height: 0,
                color: "#FFFFFF;",
              }}
            >
              Menu
            </a>
          </>
          <button
            aria-controls="offcanvasNavbar-expand-xxl"
            type="button"
            aria-label="Toggle navigation"
            class="navbar-toggler collapsed"
          >
            Menu
          </button> */}
        </div>
      </Container>
      <Navbar bg="white" expand={false} className="mb-3">
        <Container fluid>
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" />
            {isDesktop ? (
              <span>
                Deepak Savitri <br />
                Global HOSPITAL & Heart Care
              </span>
            ) : (
              ""
            )}
          </Link>

          <div>
            {" "}
            {/* <a
              href="/brakethroughcases"
              className="btn btn-primary"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              Breakthrough cases
            </a>
            <a
              href="/accreditation"
              className="btn btn-primary"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              Accreditation
            </a> */}
            <a
              href="/contact"
              className="btn btn-primary"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              Book an Appointment
            </a>
            <a
              href="/labbooking"
              className="btn btn-primary"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              Examination And Medicine Booking
            </a>
            {/* <a
              href="/feedback"
              className="btn btn-primary"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              Share Your Feedback
            </a> */}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} />
          </div>

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xxl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-xxl`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Link to="/" className="nav-link">
                  Home Page
                </Link>
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
                <Link to="/doctors" className="nav-link">
                  Speciality Doctors
                </Link>
                <Link to="/department" className="nav-link">
                 Department
                </Link>
                <Link to="/services" className="nav-link">
                  Services
                </Link>
                <Link to="/gallery" className="nav-link">
                  Gallery
                </Link>

                <Link to="/blogs" className="nav-link">
                  Blogs
                </Link>
                <Link to="/contact" className="nav-link">
                  Contact Us
                </Link>
                <Link to="/donation" className="nav-link">
                  Donation
                </Link>
                <Link to="/labbooking" className="nav-link">
                  Lab Booking
                </Link>

                <Link to="/feedback" className="nav-link">
                  Feedback
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}
