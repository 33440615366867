// import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import sanityClient from "../sanity";
import first from "../assets/cardiac.jpg";
import second from "../assets/medicine.jpg";
import third from "../assets/trauma.jpg";
// import fourth from "../assets/4.jpeg";


export default function Departmentbody() {
  const [doctors, setDoctor] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="doctor"] {
            title,
            department,
            mainImage{
                asset -> {
                    _id,
                    url
                },
                alt
            },
        }`
      )
      .then((data) => setDoctor(data))
      // .then((data) => console.log(data))
      .catch(console.error);
  }, []);


  let latestTwo = doctors.slice(0, 99);

  const renderDoctor = (latestTwo, index) => {
    return (
      <Col>
        <Card className="border-0 ">
          <Card.Body>
            <Card.Img
              variant="top"
              src={latestTwo && latestTwo.mainImage.asset.url}
            />
            <Card.Body className="px-0">
                <Card.Title>  {latestTwo.title} </Card.Title>
                <Card.Text>
               {latestTwo.department}
                </Card.Text>
              </Card.Body>
            {/* <span className="d-flex mt-2">
              <FaCalendarAlt color="grey" size={18} />
              &nbsp;  */}
              {/* {latestTwo.publishedAt.slice(0, 10)} */}
            {/* </span> */}
            {/* <h2>{latestTwo.title}</h2>
            <h6>{latestTwo.post}</h6> */}
            {/* <Link
              to="/doctors"
              className="btn btn-primary rounded-pill px-4 d-flex align-content-center justify-content-between mt-3"
              style={{ width: "200px" }}
            >
              <span>Read More</span>
              <AiOutlineRight className="mt-1" />
            </Link> */}
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <div className="homeDocs-main">
      <Container fluid className="text-center">
        {/* <p>All Department</p> */}
        <h2>All Department </h2>
      </Container>
      {/* <Container>
        <Row  xs={2} md={3} lg={4} className="g-4">
          {latestTwo.map(renderDoctor)}
        </Row>
      </Container> */}
      <Container>
        <Row xs={2} md={3} lg={4} className="g-4">
          <Col>
          <Link to="/cardiac">
            <Card  className="border-0">
              <Card.Img variant="top" src={first} />
              <Card.Body className="px-0">
                <Card.Title>Cardiac </Card.Title>
                {/* <Card.Text>
                  MBBS, DNB(Ortho)
                  <br />
                  Consultant orthopaedic surgeon
                </Card.Text> */}
              </Card.Body>
            </Card>
            </Link>
          </Col>
          <Col>
          <Link to="/cardiac">
            <Card className="border-0">
              <Card.Img variant="top" src={second} />
              <Card.Body className="px-0">
                <Card.Title>Medicine</Card.Title>
                {/* <Card.Text>
                  MBBS, MD
                  <br />
                  Consultant Pathologist
                </Card.Text> */}
              </Card.Body>
            </Card>
            </Link>
          </Col>
          <Col>
          <Link to="/cardiac">  
            <Card className="border-0">
              <Card.Img variant="top" src={third} />
              <Card.Body className="px-0">
                <Card.Title>Trauma</Card.Title>
                {/* <Card.Text>Director</Card.Text> */}
              </Card.Body>
            </Card>
            </Link>
          </Col>
          {/* <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={fourth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Demo</Card.Title>
                <Card.Text>
                  MBBS, MD(Med)
                  <br />
                  Consultant Physician
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}
