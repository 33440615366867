import React from 'react'
import { Card, Col, Container, Row } from "react-bootstrap";
import first from "../assets/1.jpeg";
import second from "../assets/2.jpeg";
import third from "../assets/3.jpeg";
// import fourth from "../assets/4.jpeg";
function DepartmentSpeciality1() {
  return (
    <div className="homeBlog-main">
        <Container fluid className="text-center">
          {/* <p>Our blog</p> */}
          <h2>Department & Speciality Doctor's</h2>
        </Container>
    
        <Container>
      <Row xs={2} md={3} lg={3} className="g-4">
        <Col>
        <h5>A. Cardiac</h5>
          <Card className="border-0"  >
            {/* <Card.Img variant="top" src={first}
             /> */}
            <Card.Body className="px-0">
              <Card.Title>Dr. Mahendra Kumar </Card.Title>
              <Card.Text>
          Senior Interventional Cardiologist
                <br />
                DNB (Cardiology)
              </Card.Text>
              <Card.Subtitle>Ex-Consultant: AIIMS, New Delhi,
                <br /> Safdarjung Hospital, New Delhi
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
       
        <Col>
        <h5>B. Emergency Trauma</h5>
          <Card className="border-0">
            {/* <Card.Img variant="top" src={first} /> */}
            <Card.Body className="px-0">
            <Card.Title>Dr. Mahendra Kumar </Card.Title>
              <Card.Text>
          MBBS, MD (Medicine), Gold Medalist,                
              </Card.Text>
              <Card.Subtitle>Ex-Consultant: AIIMS, New Delhi,
                <br /> Safdarjung Hospital, New Delhi
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
        <Col>
        <h5>C. Medicine</h5>
          <Card className="border-0">
            {/* <Card.Img variant="top" src={first} /> */}
            <Card.Body className="px-0">
            <Card.Title>1. Dr. Mahendra Kumar </Card.Title>
              <Card.Text>
          MBBS, MD (Medicine), Gold Medalist,                
              </Card.Text>
              <Card.Subtitle>Ex-Consultant: AIIMS, New Delhi,
                <br /> Safdarjung Hospital, New Delhi
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card className="border-0">
            {/* <Card.Img variant="top" src={first} /> */}
            <Card.Body className="px-0">
            <Card.Title>2. Dr. Vipul Badoni </Card.Title>
              <Card.Text>
          MBBS, DIP Card               
              </Card.Text>
              {/* <Card.Subtitle>Ex-Consultant: AIIMS, New Delhi,
                <br /> Safdarjung Hospital, New Delhi
              </Card.Subtitle> */}
            </Card.Body>
          </Card>
        </Col>
        {/* <Col>
          <Card className="border-0">
            <Card.Img variant="top" src={fourth} />
            <Card.Body className="px-0">
              <Card.Title>Dr. Demo</Card.Title>
              <Card.Text>
                MBBS, MD(Med)
                <br />
                Consultant Physician
              </Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
   

      <Row xs={2} md={3} lg={3} className="g-4" style={{marginTop:"1rem"}}>
        <Col>
        <h5>D. Surgery</h5>
          <Card className="border-0"  >
            {/* <Card.Img variant="top" src={first}
             /> */}
            <Card.Body className="px-0">
              <Card.Title>Dr. Kaushal Kumar </Card.Title>
              <Card.Text>
          MBBS, MS (Surgery)
                <br />
           
              </Card.Text>
              {/* <Card.Subtitle>Ex-Consultant: AIIMS, New Delhi,
                <br /> Safdarjung Hospital, New Delhi
              </Card.Subtitle> */}
            </Card.Body>
          </Card>
        </Col>
       
        <Col>
        <h5>E. Anesthesia</h5>
          <Card className="border-0">
            {/* <Card.Img variant="top" src={first} /> */}
            <Card.Body className="px-0">
            <Card.Title>Dr. Praveen Salar </Card.Title>
              <Card.Text>
          MBBS, MD (Anesthesia)                
              </Card.Text>
           
            </Card.Body>
          </Card>
        </Col>
        <Col>
        <h5>F. Gastroenterology</h5>
          <Card className="border-0">
            {/* <Card.Img variant="top" src={first} /> */}
            <Card.Body className="px-0">
            <Card.Title>Dr. Sachin Dev Munjal </Card.Title>
              <Card.Text>
          MBBS, MD.,DM(Gastro)           
              </Card.Text>
             
            </Card.Body>
          </Card>
        
        </Col>
        {/* <Col>
          <Card className="border-0">
            <Card.Img variant="top" src={fourth} />
            <Card.Body className="px-0">
              <Card.Title>Dr. Demo</Card.Title>
              <Card.Text>
                MBBS, MD(Med)
                <br />
                Consultant Physician
              </Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </Container>
      </div>
     
  )
}

export default DepartmentSpeciality1