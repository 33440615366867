import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import hospital from "../assets/hospital.jpg";

export default function PersonalCare() {
  return (
    <div className="personalCare-main">
      <Container>
        <Row>
          <div className="col-md-6">
            <img src={hospital} alt="" style={{ maxWidth: "inherit" }} />
          </div>
          <div className="col-md-6 d-flex align-items-top mt-3 mt-md-0">
            <div style={{maxWidth:"490px"}}>
            
            <p>
            A cardiologists can help you reduce risk. Improve overall heart health through nutrition changes
            </p>
            <Link
              to="/services"
              className="btn btn-danger rounded-pill px-4 d-flex align-content-center justify-content-between"
              style={{ width: "300px" }}
            >
              <span>Our Services & Our Facilities</span>

       
              <AiOutlineRight className="mt-1" />
            </Link>
            <br/>
            <Container>
      <Row xs={2} md={2} lg={2} className="g-4">
        <Col>
        <h5>Our Services</h5>
          <Card className="border-0"  >
            {/* <Card.Img variant="top" src={first}
             /> */}
            <Card.Body className="px-0">
              
              <Card.Text>
                a. Consultation
                <br />
                b. Medical Counselling
                <br />
                c. TMT
                <br />
                d. ECG
                <br />
                e. Holter Monitoring
                <br />
                f. Coronary Angiography
                <br />
                g. Coronary Angioplasty
                <br />
                h. Echocardiography
                <br />
                i. Stress Echocardiography
                <br />
                j. Pacemaker implantation
                <br /> 
                k. ICD implantation
                <br />
                l. 24 hrs Emergency (COMING SOON)
                <br />
                m. Dialysis 
                <br /> 
                n. OT Surgery 

               
             
              </Card.Text>
              {/* <Card.Subtitle>Ex-Consultant: AIIMS, New Delhi,
                <br /> Safdarjung Hospital, New Delhi
              </Card.Subtitle> */}
            </Card.Body>
          </Card>
        </Col>
        <Col>
        <h5>Our Facilities</h5>
          <Card className="border-0">
            {/* <Card.Img variant="top" src={first} /> */}
            <Card.Body className="px-0">
            {/* <Card.Title>Dr. Mahendra Kumar </Card.Title> */}
              <Card.Text>
              a. Advance X-Ray
              <br />
b. Path Laboratory
<br /> 
c. 24 hrs Pharmacy
<br />
d. 24 hrs Ambulance<br />
e. ICU Bed<br />
f. CCU Bed<br />
g. General Room Ward<br />
h. Ventilator (COMING SOON)<br />
i. Advance CT Scan<br />
j. Single Private Rooms<br />
k. Double Private Rooms<br />
l. Cashless Insurance Claim<br />
               
              </Card.Text>
              {/* <Card.Subtitle>Ex-Consultant: AIIMS, New Delhi,
                <br /> Safdarjung Hospital, New Delhi
              </Card.Subtitle> */}
            </Card.Body>
          </Card>
        </Col>
        
        {/* <Col>
          <Card className="border-0">
            <Card.Img variant="top" src={fourth} />
            <Card.Body className="px-0">
              <Card.Title>Dr. Demo</Card.Title>
              <Card.Text>
                MBBS, MD(Med)
                <br />
                Consultant Physician
              </Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </Container>
  

            </div>
          </div>
        </Row>
   
      </Container>
    </div>
  );
}
