import React from "react";
import DoctorsBody from "./DoctorsBody";
import Footer from "./Footer";
import NavbarTop from "./Navbar";

export default function DoctorsPage() {
  return (
    <div>
      <NavbarTop />
      <DoctorsBody />
      <Footer />
    </div>
  );
}
