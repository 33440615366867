import React from "react";
import { Container } from "react-bootstrap";

export default function ContactTitle() {
  return (
    <div className="contactTitle">
      <Container fluid className="text-center">
        <p>Contact Us</p>
        <h2>Get in Touch</h2>
      </Container>
    </div>
  );
}
