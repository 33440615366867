import React from 'react'
import Cards from './Cards'
import DepartmentSpeciality1 from './DepartmentSpeciality1'
import Donationbanner from './donationbanner'
import Footer from './Footer'
import HappyStories from './HappyStories'
import Hero from './Hero'
import Homeblogs from './Homeblogs'
import Homedocs from './Homedocs'
import NavbarTop from './Navbar'
import PersonalCare from './PersonalCare'
import ServicesFacilities from './ServicesFacilities'

export default function Home() {
  return (
    <div>
   <Donationbanner/>
        <NavbarTop/>
        <Hero/>
        <Cards/>
        <PersonalCare/>
        <DepartmentSpeciality1/>
        <HappyStories />
        {/* <ServicesFacilities/> */}
        {/* <Homeblogs/> */}
        {/* <Homedocs/> */}
        <Footer/>
    </div>
  )
}
