import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { HiDocumentPlus, HiOutlineClock } from "react-icons/hi2";
import { IoCallOutline } from "react-icons/io5";
import { RiContactsLine, RiCustomerService2Fill } from "react-icons/ri";

export default function Cards() {
  return (
    <div className="cards-main">
      <Container>
        <Row xs={1} md={2} xl={3} className="g-4">
          <Col>
            <Card className="shadow-lg0">
              <Card.Body>
                <IoCallOutline className="mb-2" size={45} color="#000" />
                <br />
                <p>Contact Numbers</p>
                <p>General Enquiry : 8272068460 / 8272068459</p>
                <hr />
                <p>Pharmacy (24hrs) : 8272068458</p>
                <hr />
                <p>Ambulance (24hrs): 8272068454</p>
                <hr />
                <p>Emergency (24hrs): 8272068455 / 8813985890</p>
                <hr />
                <p>WhatsApp for Chat: 8272068459 </p>
                <hr />
                <p>Corporate Package: 8272068460 / 8272068459</p>
                <hr />
                {/* <Card.Text>
                  Get All reports online 24x7 using your phone number or
                  email-id
                </Card.Text> */}
                {/* <Button variant="primary" className="rounded-pill">
                  Download Your Report
                </Button> */}
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="shadow-lg">
              <Card.Body>
                <HiOutlineClock className="mb-2" size={56} color="#000" />
                <br />
                <p>Timing Schedule</p>
                <h3>Working Hours</h3>
                <div>
                  <div className="d-flex justify-content-between">
                    <span>Hospital Working Hours:</span>
                    <span>All Days Open</span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span>Sunday:</span>
                    <span>No OPD</span>
                  </div>


                  <hr />
                  <div className="d-flex justify-content-between">
                    <span>OPD Timing:</span>
                    <span>11:00 AM - 2:00 PM & <br></br>  6:00 PM - 8:00 PM </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="shadow-lg">
              <Card.Body>
                <RiCustomerService2Fill
                  className="mb-2"
                  size={56}
                  color="#000"
                />
                <br />
                <p>Emergency Cases</p>
                <h4>+91 8272068455 / <br></br>+91 8813985890</h4>
                <Card.Text>
                  Get All time support for emergency. We have introduced the
                  principle of family medicine.Get Connected with us for any
                  urgency.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
