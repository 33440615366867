import React from "react";
import { Container } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="hero-main">
      <Container>
        <div className="heroContent">
          <div className="line"></div>
          <p className="">TOTAL HEALTH CARE SOLUTION</p>
          <h1>
            YOUR MOST TRUSTED
            <br />
            HEALTH PARTNER
          </h1>
          <p>
          Deepak Savitri Global HOSPITAL & Heart Care hospital is committed for providing quality patient care, diagnosis and treatment for wide range of complex and chronic diseases. A commitment to highest patient care services 
          </p>
          <Link
            to="/about"
            className="btn btn-danger rounded-pill px-4 d-flex align-content-center justify-content-between"
            style={{ width: "200px" }}
          >
            <span>About Us</span>
            <AiOutlineRight className="mt-1" />
          </Link>
        </div>
      </Container>
    </div>
  );
}
