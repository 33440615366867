import React from "react";
import image from "../assets/MonikPassportPhoto1.png";
import AKUpadhyay from "../assets/AKUpadhyay.jpg";
import image1 from "../assets/rating.png";
import image2 from "../assets/trilok-1.jpg";
import { Card, Col, Container, Row } from "react-bootstrap";
const HappyStories = ({}) => {
  return (
    < div className="homeBlog-main" ><Container fluid className="text-center">
          {/* <p>Our blog</p> */}
          <h2>Happy Stories</h2>
      </Container><div className="card-conatiner-wrap">
              <div className="card-container">
                  <img className="profile" src={image} alt="" />
                  <p>
                      DS Global Heart Hospital is very genuine hospital. It has proved a
                      boon not only to the people of Roorkee but also to the surrounding
                      areas. Dr. Mahindra Kumar is a very competent cardiologist who has all
                      the experiences to treat any kind of cardio cases. I’m really thankful
                      to him for giving me cardio treatment. I wish him and his team all the
                      very best. May God bless them with miraculous powers to serve humanity
                      always as they are doing now.
                  </p>
                  <h5>Monika Rana</h5>
                  <img className="rating" src={image1} alt="5/5" />
              </div>
              <div className="column">

                  <div className="card-containersmall">
                      <img className="profile" src={AKUpadhyay} alt="" />

                      <div className="column">
                          <p>
                              After his treatment my heart started working on 70% now. i am very
                              satisfied with Dr. Mahendra
                          </p>
                          <h5>Anand Kumar Upadhyay</h5>
                          <img className="rating" src={image1} alt="5/5" />
                      </div>
                  </div>

                  <div className="card-containersmall">
                      <img className="profile2" src={image2} alt="" />

                      <div className="column">
                          <p>
                              Dr. Mahindra is best in cardio health care.
                          </p>
                          <h5>Trilok Singh Bhuraita</h5>
                          <img className="rating" src={image1} alt="5/5" />
                      </div>
                  </div>
              </div>
          </div></div>
  );
};

export default HappyStories;

// function HappyStories() {
//   return (
//     <div></div>
//   )
// }

// export default HappyStories
