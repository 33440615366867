import React from "react";
import Footer from "./Footer";
import NavbarTop from "./Navbar";
import ServiceTitle from "./ServiceTitle";
import ServiceBody from "./ServiceBody";

export default function ServicePage() {
  return (
    <div>
      <NavbarTop />
      <ServiceTitle />
      <ServiceBody />
      <Footer />
    </div>
  );
}
