import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sericesData } from "./data";
import Footer from "../Footer";
import NavbarTop from "../Navbar";
import { Container } from "react-bootstrap";

function ServiceDetails() {
  const [serviceData, setServiceData] = useState({});
  const { service } = useParams();
  console.log(service, sericesData);

  useEffect(() => {
    const serviecsData = sericesData.find((item) => item.name === service);
    console.log(serviecsData)
    setServiceData(serviecsData);
  }, []);

  return (
    <><NavbarTop />
    <div>
    <div className="serviceTitle-main">
      <Container fluid className="text-center">
        {/* <p>Our Services</p> */}
        <h2>{sericesData.name}</h2>
      </Container>
    </div>


      <h6>{serviceData.name}</h6>
      <h6>{serviceData.desc}</h6>
    </div><Footer /></>
  );
}

export default ServiceDetails;
