import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import first from "../assets/consultation.png";
import second from "../assets/counselling.png";
import third from "../assets/echocardiography.png";
import fourth from "../assets/stressechocardiography.png";
import fifth from "../assets/tmt.png";
import sixth from "../assets/holtermonitoring.png";
import seventh from "../assets/coronaryangiography.png";
import eighth from "../assets/ecg.png";
import ninth from "../assets/coronaryangioplasty.png";
import ten from "../assets/pacemakerimplantation.png";
import eleven from "../assets/icdimplantation.png";
import tweleve from "../assets/dialysis.png";
import thirteen from "../assets/otsurgery.png";
import forteen from "../assets/emergency.png";

export default function ServiceBody() {
  return (
    <div>
      <Container>
        <Row
          xs={1}
          md={2}
          lg={6}
          className="g-4"
          style={{ margin: "100px auto" }}
        >
          <Col>
            <Link to={"/services/Consultation"}>
              <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}} >
                <Card.Body>
                  <Card.Img variant="top" src={first}  style = {{height: '5rem', width:'5rem'}}/>
                  <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>Consultation</Card.Title>
                  <Card.Text></Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col>
         <Link to={"/services/Medical-Counselling"}>   <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img  style = {{height: '5rem', width:'5rem'}}
                  variant="top"
                  src= {second}
                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>Medical Counselling</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>
          <Col>
          <Link to={"/services/Echocardiography"}>   <Card className="shadow-lg border-0 " style={{ width: '13rem' , height: '11rem'}}>
              <Card.Body >
                <Card.Img 
           style = {{height: '5rem', width:'5rem'}}
                  variant="top"
                  src= {third}
                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>Echocardiography</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>
          <Col>
          <Link to={"/services/Stress Echocardiography"}>   <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
                  src={fourth}
                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}} >
                  Stress Echocardiography
                </Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>
          <Col>
          <Link to={"/services/TMT"}>      <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
                  src={fifth}
                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>TMT</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>
          <Col>
          <Link to={"/services/Holter Monitoring"}>     <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
                  src= {sixth}
                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>Holter Monitoring</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>
          <Col>
          <Link to={"/services/Coronary Angiography"}> 
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
                  src={seventh}
                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>Coronary Angiography</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>
          <Col>      <Link to={"/services/ECG"}>
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
                  src={eighth}
                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>ECG</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card></Link>
          </Col>
          <Col>      <Link to={"/services/Coronary Angioplasty"}>
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
src={ninth}
/>
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>Coronary Angioplasty</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card></Link>
          </Col>{" "}
          <Col>      <Link to={"/services/Pacemaker implantation"}>
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
src={ten}                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>
                  Pacemaker implantation{" "}
                </Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card></Link>
          </Col>{" "}
          <Col>      <Link to={"/services/ICD implantation"}>
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
src={eleven}               />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>ICD implantation</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>{" "}
          <Col>     <Link to={"/services/Dialysis (Coming Soon)"}>
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
src={tweleve}                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>Dialysis (Coming Soon)</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body> 
            </Card> </Link>
          </Col>{" "}
          <Col>     <Link to={"/services/OT Surgery (Coming Soon)"}>
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
src={thirteen}                />
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>
                  OT Surgery (Coming Soon)
                </Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card> </Link>
          </Col>{" "}
          <Col>      <Link to={"/services/24 hrs Emergency"}>
            <Card className="shadow-lg border-0 " style={{ width: '12rem' , height: '11rem'}}>
              <Card.Body>
                <Card.Img
                  variant="top"  style = {{height: '5rem', width:'5rem'}}
src={forteen}
/>
                <Card.Title className="my-3" style={{fontSize: '1.2rem'}}>24 hrs Emergency</Card.Title>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card></Link>
          </Col>
        </Row>
      </Container>
      <Container fluid className="serviceBottom">
        <Container>
          <div className="line"></div>
          <h2 className="text-dark fw-bolder">
            We are pleased to offer you the <br />
            <span style={{ color: "#223A66" }}>chance to have the healthy</span>
          </h2>
          <a
            href="/"
            className="btn btn-danger rounded-pill px-4 d-flex align-content-center justify-content-between"
            style={{ width: "fit-content" }}
          >
            <span>GET APPOINTMENT</span>&nbsp;&nbsp;
            <AiOutlineRight className="mt-1" />
          </a>
        </Container>
      </Container>
    </div>
  );
}
