import React from "react";
import Departmentbody from "./Departmentbody";
import DoctorsBody from "./DoctorsBody";
import Footer from "./Footer";
import { Container, Row , Col } from "react-bootstrap";
import NavbarTop from "./Navbar";
import hospital from "../assets/1.jpg";
import cardiac from "../styles/cardiac.css"
export default function Cardiac() {
  return (
    <div>
      <NavbarTop />

      <Container style={{ marginTop: "80px"}}></Container>

  <Container>

  <div class="row">
    <div class="col-md-5"> <div  lg-2>
        <div>
<img src={hospital} alt="" />   

        </div>
        
       
    </div></div>
    <div class="col-md-7"><div>
    <h3>Name:- Dr.Mahendra Kumar</h3>
<h6>Specialisation - Senior Interventional Cardiologist</h6>
<h6>Experience - MBBS, MD (Medicine), Gold Medallist, DNB (Cardiology)</h6>
<h6>Recognition - Ex-Consultant- AIIMS, New Delhi, Safdarjung Hospital, New Delhi</h6>
<br/>
<a
              href="/makeappointment"
              className="btn btn-primary"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              Book an Appointment
            </a>
    </div></div>
  </div>
    
    
  


    
  </Container>
  <Container style={{ marginTop: "80px"}}></Container>
      <Footer />
    </div>
  );
}
