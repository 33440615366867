import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
// import { AiOutlineRight } from "react-icons/ai";
import Footer from "./Footer";
import NavbarTop from "./Navbar";
import { BsFillCalendar2WeekFill } from "react-icons/bs";
import sanityClient from "../sanity";
import { PortableText } from "@portabletext/react";
// import { Link } from 'react-router';

export default function BlogPage() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"] {
            title,
            slug,
            author,
            categories,
            publishedAt,
            body,
            mainImage{
                asset -> {
                    _id,
                    url
                },
                alt
            },
        }`
      )
      .then((data) => setBlogs(data))
      // .then((data) => console.log(data))
      .catch(console.error);
  }, []);

  const renderBlogs = (blogs, index) => {
    return (
      <Col key={index}>
        <Card className="my-5 border-0 shadow-lg">
          <Card.Body>
            <Card.Img src={blogs.mainImage.asset.url} alt="" />
            <p className="d-flex text-secondary fs-6 mt-3 mb-0">
              <BsFillCalendar2WeekFill size={18} />
              &nbsp; {blogs.publishedAt.slice(0, 10)}
            </p>
            <Card.Title className="mt-0">{blogs.title}</Card.Title>
            <div>
              <PortableText value={blogs.body} />
            </div>
           {/* { <Link
              to="/"
              className="btn btn-primary rounded-pill px-4 d-flex align-content-center justify-content-between"
              style={{ width: "200px" }}
            >
              <span>Read more</span>
              <AiOutlineRight className="mt-1" />
            </Link>} */}
          </Card.Body>
        </Card>
      </Col>
    );
  };

  console.log(blogs);
  return (
    <div>
      <NavbarTop />
      <div className="aboutTitle-main">
        <Container fluid className="text-center">
          <p>Our Blogs</p>
          <h2>Blog Articles</h2>
        </Container>
      </div>
      <Container>
        <Row xs={1} md={2} className="g-4 pt-5">
          {blogs.map(renderBlogs)}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
