import React from "react";
import ContactBody from "./ContactBody";
import ContactTitle from "./ContactTitle";
import Footer from "./Footer";
import NavbarTop from "./Navbar";

export default function ContactPage() {
  return (
    <div>
      <NavbarTop />
      <ContactTitle />
      <ContactBody />
      <Footer />
    </div>
  );
}
