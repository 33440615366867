import emailjs from "@emailjs/browser";
import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { AiFillCheckCircle, AiFillAlert } from "react-icons/ai";
import sanityClient from "../sanity";

export default function ContactForm() {

    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [doctors, setDoctor] = useState([]);


useEffect(() => {
  sanityClient
  .fetch(
    '*[_type == "doctor"] {title}').then((options) => 
        setOptions(options))
        .catch(console.error);
    }, []);
   
    const handleChange = event => {
      setSelectedOption(event.target.value);
    };
  
    const [handleSuccess, setHandleSuccess] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_l0bmi98",
        "template_dx9iuze",
        e.target,
        "ZwZHYipxMdB4JRNwe"
      )
      .then(
        (result) => {
          setHandleSuccess("success");
          e.target.reset();
        },
        (error) => {
          setHandleSuccess("err");
          console.log(error.text);
        }
      );
  };

  return (
    <Container style={{ margin: "100px auto" }}>
      <div className="d-flex flex-column align-items-center text-center justify-content-center my-5">
        <h2>Make An Appointment</h2>
        <div className="line my-4"></div>
        <p style={{ maxWidth: "500px" }}>

        </p>
      </div>

      <div>
        <div
          className={
            handleSuccess === "success"
              ? "alert alert-success d-flex align-items-center my-2"
              : "alert alert-success d-none align-items-center my-2"
          }
          role="alert"
        >
          <svg
            className="bi flex-shrink-0 me-2 "
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
          >
            <AiFillCheckCircle fontSize={25} />
          </svg>
          <div>Your response has been submitted successfully!</div>
        </div>
        {/* FAILED ALERT */}
        <div
          className={
            handleSuccess === "err"
              ? "alert alert-danger d-flex align-items-center my-2"
              : "alert alert-danger d-none align-items-center my-2"
          }
          role="alert"
        >
          <svg
            className="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Danger:"
          >
            <AiFillAlert fontSize={25} />
          </svg>
          <div>Response submission failed!</div>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <Row xs={1} md={2} className="g-4 my-3">
            <Col>
              <input
                type="text"
                name="from_name"
                placeholder="Full Name"
                className="w-100 text-secondary rounded p-2 fs-5 shadow-sm border-0 bg-light"
                required
              />
            </Col>
            <Col>
              <input
                type="email"
                name="user_email"
                placeholder="Email"
                className="w-100 text-secondary rounded p-2 fs-5 shadow-sm border-0 bg-light"
                required
              />
            </Col>
          </Row>
          <Row xs={1} md={2} className="g-4 mb-3">
            <Col>
            
      <select name="doctor_name" className="dropdwn" value={selectedOption} onChange={handleChange}>
        {options.map(option => (
          <option key={option._id} value={option.title}>
            {option.title}
          </option>
        ))}
      </select>

{/* <Dropdown className="dropdwn" >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
    
           

              {/* <input
                type="text"
                name="query"
                placeholder="Your Query"
                className="w-100 text-secondary rounded p-2 fs-5 shadow-sm border-0 bg-light"
                required
              /> */}
            </Col>
            <Col>
              <input
                type="number"
                name="phone_number"
                placeholder="Phone Number"
                className="w-100 text-secondary rounded p-2 fs-5 shadow-sm border-0 bg-light"
                required
              />
            </Col>
          </Row>
          <textarea
            name="message"
            placeholder="Your message here..."
            rows={5}
            className="w-100 text-secondary rounded p-2 fs-5 shadow-sm border-0 bg-light"
            required
          />
          {/* <div
            className="g-recaptcha my-2"
            data-sitekey="6LdzAj4kAAAAAIefX30dlDdwZhBWqC-GmM-hgkQ6x"
          ></div> */}
          <input
            type="submit"
            value="Submit"
            className="btn btn-primary mt-3 rounded-pill py-2 px-4"
          />
        </form>
      </div>
    </Container>
  );
}
