import React from 'react'
import { Container } from 'react-bootstrap'
import ContactForm from "./ContactForm";
import ContactTitle from "./ContactTitle";
import FeedbackForm from './feedbackform';
import NavbarTop from "./Navbar";
import Footer from "./Footer";

export default function Feedback() {
    return (
      <div>
 <NavbarTop />
 <div className="contactTitle">
      <Container fluid className="text-center">
        <p>Please share your valuable feedback</p>
        <h2>We love to hear about our doctors, facilities
and your treatment</h2>
      </Container>
    </div>
      

<FeedbackForm />
<Footer />


      </div>
    )
  }
