export const sericesData = [
  { name: "Consultation", des: "", image: "" },
  { name: "Medical Counselling", des: "", image: "" },
  {
    name: "Echocardiography",
    des: "Echocardiography is a test that uses sound waves to produce live images of your heart. The image is called an echocardiogram. This test allows your doctor to monitor how your heart and its valves are functioning. The images can help them get information about: blood clots in the heart chambers.",
    image: "",
  },
  { name: "Stress Echocardiography", des: "", image: "" },
  { name: "TMT", des: "A treadmill test (TMT) or cardiac stress test helps determine how far your heart can go before an abnormal rhythm or blood flow to the heart muscle drops. It helps your doctor know how your heart responds on being pushed. You will be asked to walk on a treadmill, and the difficulty level will be gradually increased.", image: "" },
  { name: "Holter Monitoring", des: "The Holter monitor is a type of portable electrocardiogram (ECG). It records the electrical activity of the heart continuously over 24 hours or longer while you are away from the doctor’s office. A standard or “resting” ECG is one of the simplest and fastest tests used to evaluate the heart.", image: "" },
  { name: "Coronary Angiography", des: "", image: "" },
  { name: "ECG", des: "An electrocardiogram (ECG or EKG) records the electrical signal from your heart to check for different heart conditions. Electrodes are placed on your chest to record your heart’s electrical signals, which cause your heart to beat.", image: "" },
  { name: "Coronary Angioplasty", des: "A coronary angioplasty is a procedure used to widen blocked or narrowed coronary arteries (the main blood vessels supplying the heart). The term “angioplasty” means using a balloon to stretch open a narrowed or blocked artery.", image: "" },
  { name: "Pacemaker implantation", des: "A pacemaker insertion is the implantation of a small electronic device that is usually placed in the chest (just below the collarbone) to help regulate slow electrical problems with the heart. A pacemaker may be recommended toensure that the heartbeat does not slow to a dangerously low rate", image: "" },
  { name: "ICD implantation", des: "", image: "" },
  { name: "Dialysis (Coming Soon)", des: "", image: "" },
  { name: "OT Surgery (Coming Soon)", des: "", image: "" },
  { name: "24 hrs Emergency", des: "", image: "" },
];
